import { MlBannerFluidFragment } from './fragments'
import { BannerFluidType } from './contentfulTypes'

export type BannerFluidResult = {
  mlBannerFluid: BannerFluidType
}

export default `
${MlBannerFluidFragment}
query BannerFluidQuery($id: String!) {
  mlBannerFluid(id: $id) {
    ...MlBannerFluidFragment
  }
}
`
