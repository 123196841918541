import React from 'react'
import { BannerFluid } from './BannerFluid'
import useQuery from '../../hooks/useQuery'
import bannerFluidQuery, { BannerFluidResult } from '../../graphql/bannerFluidQuery'

type Props = {
  contentfulId: string
}

export const BannerFluidWidget = ({ contentfulId }: Props) => {
  const { data, loading, error } = useQuery<BannerFluidResult>(bannerFluidQuery, { variables: { id: contentfulId } })

  if (error !== undefined || loading) return null

  return <BannerFluid {...data?.mlBannerFluid} />
}
