import React from 'react'
import useQuery from '../../hooks/useQuery'
import orBannerFluidQuery, { OrBannerFluidResult } from '../../graphql/orBannerFluidQuery'
import { BannerFluid } from './BannerFluid'

type Props = {
  listId: string
}

export const BannerFluidList = ({ listId }: Props) => {
  const { data, loading, error } = useQuery<OrBannerFluidResult>(orBannerFluidQuery, { variables: { listId } })
  const orList = data?.orList
  const block = orList?.itemsCollection.items[0]

  if (error !== undefined || (!orList?.itemsCollection.items.length && !loading)) return null

  return <BannerFluid {...block} />
}
