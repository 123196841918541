import { BannerFluidType, BlockType } from './contentfulTypes'
import { MlBannerFluidFragment } from './fragments'

export interface OrBannerFluidResult {
  orList: {
    blockType: BlockType.BANNER_FLUID
    itemsCollection: {
      items: BannerFluidType[]
    }
  }
}

export default `
${MlBannerFluidFragment}
query OrBannerFluidQuery($listId: String!) {
  orList(id: $listId) {
    itemsCollection {
      items {
        ...MlBannerFluidFragment
      }
    }
  }
}
`
