import styled, { css } from 'styled-components'
import { breakpoints, Image, useDimensions, getStoredMarket } from '@ecommerce/shared'
import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { BannerFluidType } from '../../graphql/contentfulTypes'
import slugFallback from '../../utils/slugFallback'

const WrapperBannerFluid = styled.div<{ hasLink?: boolean; backgroundColor?: string }>`
  width: 100%;
  display: flex;
  ${({ hasLink }) =>
    hasLink &&
    css`
      cursor: pointer;
    `};

  div {
    width: auto;
    flex-grow: 1;
  }

  .left {
    background-color: red;
  }

  .right {
    background-color: yellow;
  }

  .content-image {
    all: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 140px;
    overflow: hidden;
    ${({ backgroundColor }) => `background: ${backgroundColor}`};
    .image {
      max-width: 100%;
      position: absolute;
      width: 100%;
      object-fit: cover;
    }
  }

  .asset {
    width: 100%;
    margin: 0 auto;
    max-height: 312px;
    background: ${({ theme, backgroundColor }) => backgroundColor ?? theme.colors.background.bodyAlternative};
  }

  @media (${breakpoints.tabletLandscape.min}) {
    .asset {
      max-height: 372px;
    }
  }
`
type Props = Omit<BannerFluidType, 'contentful_id' | 'name'>

export const BannerFluid = ({
  backgroundColor,
  imageDesktop,
  imageTablet,
  imageMobile,
  link,
  videoMobile,
  videoDesktopTablet,
}: Props) => {
  const [ContainerRef, ContainerDimensions] = useDimensions<HTMLImageElement>()

  const getAsset = () => {
    if (!ContainerDimensions.width) {
      return
    }
    if (imageDesktop && imageTablet && imageMobile) {
      if (ContainerDimensions.width <= 768) {
        return imageMobile.url
      }
      if (ContainerDimensions.width <= 1024 && ContainerDimensions.width > 768) {
        return imageTablet.url
      }
      if (ContainerDimensions.width > 1024) {
        return imageDesktop.url
      }
    }

    if (videoMobile && videoDesktopTablet) {
      if (ContainerDimensions.width <= 768) {
        return videoMobile?.url
      }
      return videoDesktopTablet?.url
    }
  }

  const [assetToShow, setAssetToShow] = useState(getAsset())

  useEffect(() => {
    setAssetToShow(getAsset())
  }, [ContainerDimensions.width])

  return (
    <WrapperBannerFluid ref={ContainerRef} hasLink={!!link} backgroundColor={backgroundColor}>
      {videoMobile && videoDesktopTablet ? (
        <video
          className="asset"
          src={assetToShow}
          loop
          autoPlay
          muted
          onClick={() => {
            if (link) {
              navigate(slugFallback(link) || '/')
            }
          }}
        />
      ) : (
        <button
          className="content-image"
          type="button"
          onClick={() => {
            if (link) {
              navigate(slugFallback(link) || '/')
            }
          }}
        >
          <Image
            className="asset image"
            src={getAsset()}
            alt="banner"
            params={{
              q: 90,
            }}
          />
        </button>
      )}
    </WrapperBannerFluid>
  )
}
